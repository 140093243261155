import { Smartphone } from 'lucide-react'

type SmartphoneDisabledIconProps = {
  smartPhoneIcon?: {
    className: string
    size: string | number
  }
  diagonalSlash?: {
    bgColor: string
  }
}
export const SmartphoneDisabledIcon = ({
  smartPhoneIcon,
  diagonalSlash,
}: SmartphoneDisabledIconProps) => {
  if (!smartPhoneIcon) {
    smartPhoneIcon = {
      className: 'text-themed-blue-500',
      size: 18,
    }
  }
  if (!diagonalSlash) {
    diagonalSlash = {
      bgColor: 'bg-themed-blue-500',
    }
  }
  const slashWidth =
    typeof smartPhoneIcon.size === 'number'
      ? `${Number(smartPhoneIcon.size) * 1.422}px`
      : '100%'

  const containerSize =
    typeof smartPhoneIcon.size === 'number'
      ? `${smartPhoneIcon.size}px`
      : smartPhoneIcon.size

  return (
    <div
      className="relative inline-block -translate-y-[1.5px]"
      style={{
        width: containerSize,
        height: containerSize,
      }}
    >
      <Smartphone
        size={smartPhoneIcon.size}
        className={smartPhoneIcon.className}
      />
      <div
        className="absolute inset-0 z-10"
        style={{
          transform: 'rotate(-45deg)',
          transformOrigin: 'center',
        }}
      >
        <div
          className={`absolute top-1/2 -translate-x-[5px] left-0 w-full h-0.5 ${diagonalSlash.bgColor}`}
          style={{
            width: slashWidth,
          }}
        />
      </div>
    </div>
  )
}
