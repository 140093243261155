import { useParams } from 'react-router-dom'
import {
  programsNewCollection,
  selectDeviceEligibilityMessages,
  selectDeviceIneligibilityMessages,
} from '@/reducers/programsNew'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useProgramRequirements } from '@/app/hooks/useProgramRequirements'
import { useAppDispatch, useAppSelector, useMockableViewModel } from '@/hooks'
import { thermostatsCollection } from '@/reducers/thermostats'
import {
  selectInProgressEnrollment,
  setSelectedDevices,
} from '@/reducers/inProgressUtilityProgramEnrollments'
import { useNavigation } from '@/app/hooks'
import { useBottomSheet } from '@/context/bottom-sheet-context'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import {
  useCalculateTotalAwardedDollars,
  getDeviceIncentives,
} from '../utils/utils'
import { UtilityProgram } from '@/types/utilityProgram'

export type UseViewModelProps = ReturnType<typeof useViewModel>

function useViewModel() {
  const { id: utilityProgramId } = useParams<{ id: string }>()
  const navigate = useNavigation()
  const { snapToHeader } = useBottomSheet()
  const { data: utilityPrograms, isLoading: isLoadingProgram } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0]
  programsNewCollection.useFetch()
  const deviceIneligibility = useSelector(
    selectDeviceIneligibilityMessages(+utilityProgramId),
  )
  const deviceEligibility = useSelector(
    selectDeviceEligibilityMessages(+utilityProgramId),
  )
  const program = useAppSelector(selectInProgressEnrollment(+utilityProgramId))
  const dispatch = useAppDispatch()
  const { routeToNextStep } = useProgramRequirements(utilityProgram)

  const updateDevices = (deviceUpdate: {
    vehicleIds?: number[]
    chargerIds?: number[]
    thermostatIds?: number[]
  }) => {
    dispatch(
      setSelectedDevices({
        utilityProgramId: +utilityProgramId,
        selectedDevices: {
          vehicleIds: deviceUpdate.vehicleIds ?? [],
          chargerIds: deviceUpdate.chargerIds ?? [],
          thermostatIds: deviceUpdate.thermostatIds ?? [],
        },
      }),
    )
  }

  const [selectedEligibleThermostatIds, setSelectedEligibleThermostatIds] =
    useState<number[]>(program?.selectedDevices.thermostatIds ?? [])
  const [selectedEligibleVehicleIds, setSelectedEligibleVehicleIds] = useState<
    number[]
  >(program?.selectedDevices.vehicleIds ?? [])
  const [selectedEligibleChargerIds, setSelectedEligibleChargerIds] = useState<
    number[]
  >(program?.selectedDevices.chargerIds ?? [])

  const toggleVehicleEligibility = (id: number) => {
    setSelectedEligibleVehicleIds((prev) =>
      prev.includes(id) ? prev.filter((vid) => vid !== id) : [...prev, id],
    )
  }

  const toggleThermostatEligibility = (id: number) => {
    setSelectedEligibleThermostatIds((prev) =>
      prev.includes(id) ? prev.filter((tid) => tid !== id) : [...prev, id],
    )
  }

  const toggleChargerEligibility = (id: number) => {
    setSelectedEligibleChargerIds((prev) =>
      prev.includes(id) ? prev.filter((cid) => cid !== id) : [...prev, id],
    )
  }

  const onContinueClick = () => {
    updateDevices({
      chargerIds: selectedEligibleChargerIds,
      thermostatIds: selectedEligibleThermostatIds,
      vehicleIds: selectedEligibleVehicleIds,
    })
    // only need to manually route if the user goes back and changes their selection
    if (program?.selectedDevices) {
      routeToNextStep()
    }
  }
  const vehicles = useAppSelector((state) => state.vehicles.vehicles)
  const thermostats = useAppSelector((state) =>
    thermostatsCollection.selectors.selectAll(state),
  )
  const chargers = useAppSelector((state) => state.chargers.chargers)
  // reset selected vehicles if any changes are made to the eligible vehicles
  useEffect(() => {
    if (
      program?.selectedDevices.vehicleIds.some(
        (d) => !vehicles?.find((vehicle) => vehicle.id === d),
      )
    ) {
      setSelectedEligibleVehicleIds([])
      updateDevices({ vehicleIds: [] })
    }
  }, [vehicles])
  // reset selected thermostats if any changes are made to the eligible thermostats
  useEffect(() => {
    if (
      program?.selectedDevices.thermostatIds.some(
        (d) => !thermostats?.find((thermostat) => thermostat.id === d),
      )
    ) {
      setSelectedEligibleThermostatIds([])
      updateDevices({ thermostatIds: [] })
    }
  }, [thermostats])
  // reset selected chargers if any changes are made to the eligible chargers
  useEffect(() => {
    if (
      program?.selectedDevices.chargerIds.some(
        (d) => !chargers?.find((charger) => charger.id === d),
      )
    ) {
      setSelectedEligibleChargerIds([])
      updateDevices({ chargerIds: [] })
    }
  }, [chargers])

  const status = useAppSelector((state) => state.utilityPrograms.data.status)
  const isLoading = useMemo(() => {
    return isLoadingProgram || status === 'loading'
  }, [isLoadingProgram, status])

  const eligibleDeviceCount =
    (deviceEligibility?.vehicles?.length ?? 0) +
    (deviceEligibility?.thermostats?.length ?? 0) +
    (deviceEligibility?.chargers?.length ?? 0)
  const ineligibleDeviceCount =
    (deviceIneligibility?.vehicles?.length ?? 0) +
    (deviceIneligibility?.thermostats?.length ?? 0) +
    (deviceIneligibility?.chargers?.length ?? 0)

  const initialTabIndex = ineligibleDeviceCount === 0 ? 1 : 0

  const onAddDevice = () => {
    navigate.push('/connect-device')
    snapToHeader()
  }

  const deviceIncentives = getDeviceIncentives(utilityProgram as UtilityProgram)
  const totalAwardedDollars = useCalculateTotalAwardedDollars(
    selectedEligibleVehicleIds,
    selectedEligibleThermostatIds,
    selectedEligibleChargerIds,
    deviceIncentives,
  )
  const maxRewardForDevice = Math.max(
    deviceIncentives.vehicle,
    deviceIncentives.thermostat,
    deviceIncentives.charger,
  )
  return {
    selectedEligibleThermostatIds,
    selectedEligibleVehicleIds,
    selectedEligibleChargerIds,
    toggleVehicleEligibility,
    toggleThermostatEligibility,
    toggleChargerEligibility,
    isLoading,
    onContinueClick,
    utilityProgram,
    initialTabIndex,
    deviceIneligibility,
    deviceEligibility,
    eligibleDeviceCount,
    ineligibleDeviceCount,
    onAddDevice,
    deviceIncentives,
    totalAwardedDollars,
    maxRewardForDevice,
  }
}

function useMockViewModel() {
  return {
    eligibleThermostats: [],
    eligibleChargers: [],
    eligibleVehicles: [],
    selectedEligibleThermostatIds: [],
    selectedEligibleVehicleIds: [],
    selectedEligibleChargerIds: [],
    toggleVehicleEligibility: () => {},
    toggleThermostatEligibility: () => {},
    toggleChargerEligibility: () => {},
    isLoading: false,
    onContinueClick: () => {},
    utilityProgram: {} as any,
    initialTabIndex: 0,
    deviceRequirements: undefined,
    deviceIneligibility: null,
    deviceEligibility: null,
    eligibleDeviceCount: 0,
    ineligibleDeviceCount: 0,
    onAddDevice: () => {},
    deviceIncentives: {
      vehicle: 50,
      thermostat: 0,
      charger: 0,
      behavioral: 0,
      maxRewardDollars: 300,
    },
    totalAwardedDollars: 0,
    maxRewardForDevice: 0,
  }
}

export default useMockableViewModel({
  useViewModel,
  useMockViewModel,
})
