import { ReactNode, useMemo, useState } from 'react'
import { Button, LoadingAnimation, Text } from '@/components'

import { DollarSignIcon, DotIcon, PlusIcon, SmartphoneIcon } from 'lucide-react'
import Tabs from '@/components/Tabs'
import LostSomewhere from '@/app/img/lost-somewhere.svg'
import CircleCheckbox from '@/authenticated/components/CircleCheckbox'
import { SmartphoneDisabledIcon } from '@/app/components/SmartphoneDisabledIcon/SmartphoneDisabledIcon'
import { P, match } from 'ts-pattern'
import { DeviceEligibility, DeviceIneligibility } from '@/reducers/programsNew'
import {
  UseViewModelProps,
  default as useViewModel,
} from '@/app/pages/multi-program-enrollment/device-selection/viewModel'
import NumberFlow from '@number-flow/react'
import { AnimatePresence, motion } from 'framer-motion'

enum TabName {
  INELIGIBLE = 'Ineligible',
  ELIGIBLE = 'Eligible',
}

const TAB_KEY = 'program-select-devices-tabs'

export default function UtilityProgramDeviceSelectionPage() {
  const {
    utilityProgram,
    selectedEligibleChargerIds,
    selectedEligibleThermostatIds,
    selectedEligibleVehicleIds,
    toggleVehicleEligibility,
    toggleThermostatEligibility,
    toggleChargerEligibility,
    initialTabIndex,
    deviceIneligibility,
    deviceEligibility,
    onContinueClick,
    ineligibleDeviceCount,
    eligibleDeviceCount,
    onAddDevice,
    deviceIncentives,
    totalAwardedDollars,
    maxRewardForDevice,
  } = useViewModel()

  const tabs = [
    `Ineligible (${ineligibleDeviceCount})`,
    `Eligible (${eligibleDeviceCount})`,
  ]
  const selectedDeviceCount = useMemo(
    () =>
      selectedEligibleChargerIds.length +
      selectedEligibleThermostatIds.length +
      selectedEligibleVehicleIds.length,
    [
      selectedEligibleVehicleIds,
      selectedEligibleThermostatIds,
      selectedEligibleChargerIds,
    ],
  )
  const [tabIndex, setTabIndex] = useState(0)
  const selectedTabName = useMemo(
    () =>
      match(tabIndex)
        .with(0, () => TabName.INELIGIBLE)
        .with(1, () => TabName.ELIGIBLE)
        .otherwise(() => TabName.INELIGIBLE),
    [tabIndex],
  )
  // prevent loading animation from flickering
  const loadingAnimation = useMemo(
    () => (
      <div className={'flex justify-center items-center h-full'}>
        <LoadingAnimation type={'falling'} />
      </div>
    ),
    [],
  )
  if (!utilityProgram) {
    return <>{loadingAnimation}</>
  }

  return (
    <div className="flex flex-col gap-4">
      <section className="flex flex-col gap-2">
        <Text variant="h3">Review Devices</Text>
        <Text variant="body2">
          Review your ineligible devices, you might be able to earn extra
          dollars if you fix your permissions on your devices.
        </Text>
      </section>
      <section className="w-full h-fit flex items-center justify-center">
        <DeviceRewards
          maxRewardDollars={deviceIncentives.maxRewardDollars}
          totalAwardedDollars={totalAwardedDollars}
        />
      </section>
      <section className="flex flex-col items-center space-y-6">
        <Tabs.Tabs
          initialTabIndex={initialTabIndex}
          tabKey={TAB_KEY}
          labels={tabs}
          tabBarAttributes={{ className: 'max-w-none' }}
          onTabChange={(index) => {
            Tabs.setActiveTabIndex(TAB_KEY, index)
            setTabIndex(index)
          }}
        />
        <Tabs.Content tabKey="program-select-devices-tabs">
          <div className={'flex justify-start w-full'}>
            <TabContentRoot
              header={
                <TabContentHeader
                  tabName={TabName.INELIGIBLE}
                  deviceCount={ineligibleDeviceCount}
                />
              }
              body={
                <>
                  {deviceIneligibility ? (
                    <IneligibleDevices
                      deviceIneligibility={deviceIneligibility}
                      ineligibleDeviceCount={ineligibleDeviceCount}
                    />
                  ) : (
                    loadingAnimation
                  )}
                </>
              }
            />
          </div>
          <div className={'flex justify-start w-full [&>*]:select-none'}>
            <TabContentRoot
              header={
                <TabContentHeader
                  tabName={TabName.ELIGIBLE}
                  deviceCount={eligibleDeviceCount}
                />
              }
              body={
                <>
                  {deviceEligibility ? (
                    <EligibleDevices
                      eligibleDeviceCount={eligibleDeviceCount}
                      deviceEligibility={deviceEligibility}
                      selectedEligibleVehicleIds={selectedEligibleVehicleIds}
                      toggleVehicleEligibility={toggleVehicleEligibility}
                      selectedEligibleThermostatIds={
                        selectedEligibleThermostatIds
                      }
                      toggleThermostatEligibility={toggleThermostatEligibility}
                      selectedEligibleChargerIds={selectedEligibleChargerIds}
                      toggleChargerEligibility={toggleChargerEligibility}
                      onAddDevice={onAddDevice}
                      deviceIncentives={deviceIncentives}
                      totalAwardedDollars={totalAwardedDollars}
                      maxRewardForDevice={maxRewardForDevice}
                    />
                  ) : (
                    loadingAnimation
                  )}
                </>
              }
            />
          </div>
        </Tabs.Content>
      </section>
      {match(selectedTabName)
        .with(TabName.INELIGIBLE, () => (
          <Button
            id="next-devices-button"
            onClick={() => {
              Tabs.setActiveTabIndex(TAB_KEY, 1)
              setTabIndex(1)
            }}
          >
            Next
          </Button>
        ))
        .with(TabName.ELIGIBLE, () => (
          <Button
            id="enroll-devices-button"
            disabled={selectedDeviceCount === 0}
            onClick={onContinueClick}
          >
            Continue
          </Button>
        ))
        .exhaustive()}
    </div>
  )
}

function TabContentRoot(props: { header: ReactNode; body: ReactNode }) {
  return (
    <div className="w-full justify-center border-solid rounded-md flex flex-col border border-themed-grey-200">
      {props.header}
      <div className="py-4">{props.body}</div>
    </div>
  )
}

function TabContentHeader(props: { tabName: TabName; deviceCount: number }) {
  const icon = match(props)
    .with(P.union({ deviceCount: 0 }, { tabName: TabName.INELIGIBLE }), () => (
      <SmartphoneDisabledIcon />
    ))
    .otherwise(() => (
      <SmartphoneIcon className="text-themed-blue-500 flex-none" size="18" />
    ))
  return (
    <div className="flex flex-row gap-4 items-center border-b border-themed-grey-200 border-solid [&>*]:my-4">
      <div className="flex items-center justify-center bg-themed-blue-100 rounded-2xl p-2 ml-4">
        {icon}
      </div>
      <Text variant="body1">
        {props.tabName} devices ({props.deviceCount})
      </Text>
    </div>
  )
}

type IneligibleDevicesProps = {
  ineligibleDeviceCount: number
  deviceIneligibility: DeviceIneligibility
}

function IneligibleDevices({
  deviceIneligibility,
  ineligibleDeviceCount,
}: IneligibleDevicesProps) {
  if (ineligibleDeviceCount === 0) {
    return <NoDevicesContent kind="Ineligible" />
  }
  return (
    <ul className="flex flex-col gap-4 px-2">
      {deviceIneligibility?.vehicles.map((v) => (
        <li key={v.id}>
          <Text variant="body1" className="flex items-center">
            <DotIcon /> {v.vehicle.car.car_model?.year}{' '}
            {v.vehicle.car.car_model?.friendly_name}
          </Text>
          <Text variant="body2" className="px-6">
            {v.requirements?.[0].content}
          </Text>
        </li>
      ))}
      {deviceIneligibility?.thermostats.map((v) => (
        <li key={v.id}>
          <Text variant="body2" className="flex items-center">
            <DotIcon /> {v.thermostat.name}
          </Text>
          <Text variant="body2" className="px-6">
            {v.requirements?.[0].content}
          </Text>
        </li>
      ))}
      {deviceIneligibility?.chargers.map((v) => (
        <li key={v.id}>
          <Text variant="body2" className="flex items-center">
            <DotIcon /> {v.charger.name}
          </Text>
          <Text variant="body2" className="px-6">
            {v.requirements?.[0].content}
          </Text>
        </li>
      ))}
    </ul>
  )
}

function EligibleDevices({
  selectedEligibleVehicleIds,
  toggleVehicleEligibility,
  selectedEligibleThermostatIds,
  toggleThermostatEligibility,
  selectedEligibleChargerIds,
  toggleChargerEligibility,
  deviceEligibility,
  eligibleDeviceCount,
  onAddDevice,
  deviceIncentives,
  totalAwardedDollars,
  maxRewardForDevice,
}: {
  selectedEligibleVehicleIds: number[]
  toggleVehicleEligibility: (id: number) => void
  selectedEligibleThermostatIds: number[]
  toggleThermostatEligibility: (id: number) => void
  selectedEligibleChargerIds: number[]
  toggleChargerEligibility: (id: number) => void
  deviceEligibility: DeviceEligibility
  eligibleDeviceCount: number
  onAddDevice: () => void
  deviceIncentives: UseViewModelProps['deviceIncentives']
  totalAwardedDollars: number
  maxRewardForDevice: number
}) {
  if (eligibleDeviceCount === 0) {
    return <NoDevicesContent kind="Eligible" />
  }

  return (
    <div className="flex flex-col gap-4 px-4">
      {deviceEligibility?.vehicles?.map((v) => (
        <div
          key={v.id}
          className="items-center flex flex-row gap-4 cursor-pointer w-fit rounded-md -m-2 p-2"
          onClick={() => toggleVehicleEligibility(v.id)}
        >
          <CircleCheckbox
            activeColor="#00D73F"
            checked={selectedEligibleVehicleIds.includes(v.id)}
            style={{ padding: '0px' }}
          />
          <Text variant="body2" className="mt-0.5">
            {v.vehicle.car.car_model?.year}{' '}
            {v.vehicle.car.car_model?.friendly_name}
          </Text>
          <IncentiveChip
            amount={deviceIncentives.vehicle}
            isSelected={selectedEligibleVehicleIds.includes(v.id)}
          />
        </div>
      ))}
      {deviceEligibility?.thermostats?.map((t) => (
        <div
          key={t.id}
          className="items-center flex flex-row gap-4 cursor-pointer w-fit rounded-md -m-2 p-2"
          onClick={() => toggleThermostatEligibility(t.id)}
        >
          <CircleCheckbox
            activeColor="#00D73F"
            checked={selectedEligibleThermostatIds.includes(t.id)}
            style={{ padding: '0px' }}
          />
          <Text variant="body2">{t.thermostat.name}</Text>
          <IncentiveChip
            amount={deviceIncentives.thermostat}
            isSelected={selectedEligibleThermostatIds.includes(t.id)}
          />
        </div>
      ))}
      {deviceEligibility?.chargers?.map((c) => (
        <div
          key={c.id}
          className="items-center flex flex-row gap-4 cursor-pointer w-fit rounded-md -m-2 p-2"
          onClick={() => toggleChargerEligibility(c.id)}
        >
          <CircleCheckbox
            activeColor="#00D73F"
            checked={selectedEligibleChargerIds.includes(c.id)}
            style={{ padding: '0px' }}
          />
          <Text variant="body2">{c.charger.name}</Text>
          <IncentiveChip
            amount={deviceIncentives.charger}
            isSelected={selectedEligibleChargerIds.includes(c.id)}
          />
        </div>
      ))}
      <div className="border-t border-solid border-themed-grey-200" />
      <div
        className="flex items-center gap-4 cursor-pointer rounded-lg w-full min-w-fit -my-3 -mx-2 px-2 py-3 relative "
        onClick={onAddDevice}
      >
        <PlusIcon className="flex-none bg-themed-blue-100 rounded-lg p-1 text-themed-blue-500 inline-block" />
        <Text variant="body2" className="h-7 place-content-center">
          Add another device
        </Text>
        <AnimatePresence>
          {!(maxRewardForDevice < totalAwardedDollars) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeIn' }}
            >
              <IncentiveChip
                prefix="Up to"
                amount={maxRewardForDevice}
                isSelected={false}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

function NoDevicesContent(props: { kind: 'Eligible' | 'Ineligible' }) {
  const message =
    props.kind === 'Eligible'
      ? 'No Devices. Add Devices to earn money!'
      : 'No ineligible devices'
  return (
    <div className="flex flex-col gap-4 my-4 justify-center items-center">
      <Text variant={'body1'}>{message}</Text>
      {props.kind !== 'Eligible' && (
        <img src={LostSomewhere} alt="lost somewhere image" />
      )}
    </div>
  )
}

function DeviceRewards(props: {
  maxRewardDollars: number
  totalAwardedDollars: number
}) {
  return (
    <div className="flex flex-row justify-center items-center gap-4 border border-solid p-8 rounded-md border-themed-grey-200 h-fit w-full md:max-w-md">
      <div className="w-28 h-full flex flex-col gap-1 justify-center items-center">
        <DollarSignIcon className="text-themed-grey-400 flex-none" />
        <Text className="text-center whitespace-nowrap" variant="body2">
          Maximum Reward
        </Text>
        <Text className="text-themed-blue-500 text-center h-6">
          Up to ${props.maxRewardDollars}
        </Text>
      </div>
      <div className="w-[1px] h-20 mx-2 bg-themed-grey-200" />
      <div className="w-28 h-full flex flex-col gap-1 justify-center items-center">
        <DollarSignIcon className="text-themed-grey-400 flex-none" />
        <Text className="text-center" variant="body2">
          Your Reward
        </Text>
        <Text className="text-themed-green-900 text-center h-6">
          $<NumberFlow value={props.totalAwardedDollars} />
        </Text>
      </div>
    </div>
  )
}

function IncentiveChip(props: {
  prefix?: string
  amount: number
  isSelected: boolean
}) {
  if (props.amount === 0) {
    return null
  }
  return (
    <div
      className={`px-2 py-1 rounded-lg ${
        props.isSelected
          ? 'bg-themed-green-100 text-themed-green-900'
          : 'bg-themed-blue-100 text-themed-blue-500'
      } font-bold text-sm`}
    >
      {props.prefix} ${props.amount}
    </div>
  )
}
